.App-header {
  background: radial-gradient(
    circle,
    #282c34,
    #20252b,
    #14171c,
    #0a0b0e,
    #000000
  );
  background-size: 400% 400%;
  min-height: 100vh;
  height: 500vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  animation: gradientShift 20s ease infinite;
}
@keyframes gradientShift {
  0% {
    background-position: 50% 50%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 50% 50%;
  }
}
/* Style the scrollbar */
::-webkit-scrollbar {
  width: 8px; /* Set the width of the scrollbar */
  height: 8px; /* Set the height of the scrollbar (for horizontal scroll) */
}

/* Style the scrollbar handle (the part you can drag) */
::-webkit-scrollbar-thumb {
  background-color: crimson; /* Set the color of the scrollbar handle */
  border-radius: 10px; /* Make the handle rounded */
}

/* Style the scrollbar handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: white; /* Change color on hover */
}

/* Make the scrollbar track and everything else transparent */
::-webkit-scrollbar-track {
  background: transparent; /* Make the track transparent */
  border-radius: 10px;
}

::-webkit-scrollbar-button {
  background: transparent; /* Remove the scrollbar buttons if any */
}

section {
  min-height: 100vh;
  height: auto;
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
  display: inline-block;
  margin-top: 6px; /*these margins are here because the nav smooth scroll offset is broke and for whatever reason this works.*/
  margin-bottom: 5px; /*red border clarifies a little bit but not much*/
  /*border:1px solid red;*/
}

.navContainer .active {
  color: crimson;
}

/* The class for the Contact section Box */
.contact-section-box {
  color: #282c34 !important;
  width: 500px !important;
  height: 300px !important;
  background-color: white !important;
  border-radius: 25px !important;
  margin: 20px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1) !important;
  position: relative !important;
  overflow: hidden !important;
  transition: box-shadow 0.3s ease !important;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .contact-section-box {
    width: 250px !important;
    height: 450px !important;
  }
  .App-header {
    height: auto;
  }
}

@media (max-width: 480px) {
  .contact-section-box {
    width: 90% !important;
    max-width: 300px !important;
    height: 150px !important;
    flex-grow: 1 !important;
  }
}

/* Hover effect - background color transition */
.contact-section-box:hover {
  cursor: pointer !important;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3) !important;
}

/* Create the animated background fill effect */
.contact-section-box::before {
  content: "" !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  background-color: #4682b4 !important; /* Steel blue background */
  z-index: -1 !important;
  width: 100% !important; /* Start with full width */
  height: 0% !important; /* Start with no fill */
  opacity: 0; /* Start invisible */
  transition: opacity 0.5s ease, height 0.5s ease !important; /* Transition opacity and height */
}

/* On hover, animate the background height and opacity */
.contact-section-box:hover::before {
  height: 100% !important; /* Fill the box vertically */
  opacity: 1 !important; /* Make the background visible */
}
